// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import About from './About';
import WorkPage from './WorkPage';
import JFFPage from './JFFPage';
import PrescriptionPage from './PrescriptionPage';

import './App.css';


// ScrollToTop component
function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/works/:workId" element={<WorkPage />} />

          <Route path="/jff" element={<JFFPage />} />
          <Route path="/prescription" element={<PrescriptionPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;