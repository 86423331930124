import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import BackButton from "./components/BackButtonBlack";
import PageNavigator from "./components/PageNavigator";
import "./MovieGamePage.css";
import Footer from "./components/Footer";
import ProjectNavigator from "./components/ProjectNavigator";

function MovieGamePage() {
  const [isMobile, setIsMobile] = useState(false);

  // Check if the screen width is mobile-sized on component mount
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Animation variant for desktop, non-animated display for mobile
  const groupAnimation = isMobile
    ? {
        hidden: { opacity: 1, y: 0, filter: "blur(0px)" },
        visible: { opacity: 1, y: 0, filter: "blur(0px)" },
      }
    : {
        hidden: { opacity: 0, y: 0, filter: "blur(10px)" },
        visible: { opacity: 1, y: 0, filter: "blur(0px)" },
      };

  return (
    <div className="movie-game-page">
      {!isMobile && <PageNavigator />}
      {isMobile && <BackButton />}

      <h1 className="movie-game-title">Actor to Actor Game</h1>
      <div className="movie-image-container">
        <a
          href="https://actortoactor.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="play-button">Play the Game</button>
        </a>
        <img
          src="/a2a_new.png"
          alt="Actor to Actor game"
          className="movie-image"
        />
      </div>

      <div className="movie-game-content">
        <div className="project-info-container">
          <div className="project-info">
            <div className="project-detail">
              <span className="label">Year:</span>
              <span className="value">June 2024 - October 2024</span>
            </div>
            <div className="project-detail">
              <span className="label">Tools:</span>
              <span className="value">Figma, Python, React</span>
            </div>
            <div className="project-detail">
              <span className="label">Role:</span>
              <span className="value">
                UI/UX Designer, Full-stack Development
              </span>
            </div>
            <div className="project-detail">
              <span className="label">Summary:</span>
              <span className="value">Internship Project</span>
            </div>
          </div>
        </div>

        <hr className="divider" />

        {/* Animated Sections */}
        {/* Conditionally render motion.div or div based on screen size */}
        {isMobile ? (
          <div className="movie-game-description">
            <h2>Project Summary</h2>
            <p>
              The Actor to Actor Game is a web-based game inspired by the "Six
              Degrees of Kevin Bacon" theory. It challenges users to connect two
              actors through their co-star network in movies. The platform
              combines intuitive UI/UX design with complex algorithmic
              problem-solving for an engaging user experience.
            </p>

            <p>
              This project was developed as part of an internship with VALUENEX,
              a leading data analytics company specializing in predictive
              analytics and intellectual property analysis. The Actor to
              Actor Game was designed to gamify data analysis and showcase
              VALUENEX's advanced Network Analysis capabilities in a unique and
              engaging way. The goal was to creatively highlight the potential
              of the company's advanced tools by building a gamified experience
              that reflects the innovative approach VALUENEX applies in its data
              analytics solutions. 
            </p>

            {/* Embed Video */}
            <div className="video-container">
              <video width="100%" controls>
                <source src="/a2av2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.2 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>Project Summary</h2>
            <p>
              The Actor to Actor Game is a web-based game inspired by the "Six
              Degrees of Kevin Bacon" theory. It challenges users to connect two
              actors through their co-star network in movies. The platform
              combines intuitive UI/UX design with complex algorithmic
              problem-solving for an engaging user experience.
            </p>
            <p>
              This project was developed as part of an internship with VALUENEX,
              a leading data analytics company specializing in predictive
              analytics and intellectual property analysis. The Actor to
              Actor Game was designed to gamify data analysis and showcase
              VALUENEX's advanced Network Analysis capabilities in a unique and
              engaging way. The goal was to creatively highlight the potential
              of the company's advanced tools by building a gamified experience
              that reflects the innovative approach VALUENEX applies in its data
              analytics solutions. 
            </p>

            {/* Embed Video */}
            <div className="video-container">
              <video width="100%" controls>
                <source src="/a2av2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </motion.div>
        )}

        {/* Repeat the above pattern for other sections */}
        {isMobile ? (
          <div className="movie-game-description">
            <h2>UI/UX Design Process</h2>
            <h3>User-Centric Design Philosophy</h3>
            <p>
              The UI/UX design for the game was aimed to deliver an intuitive
              and engaging experience, especially given its novel and unfamiliar
              gameplay mechanics.
            </p>
            <h3>1. Problem Definition</h3>
            <ul>
              <li>
                <strong>Goal</strong>: Simplify and clarify the gameplay
                mechanics so that users could easy understand and engage
                with the task of connecting actors.
              </li>
              <li>
                <strong>Challenge</strong>: Design and develop an intuitive interface that conveys a graph-based network of actors and movies without overwhelming users, while leveraging the potential of network analysis in an innovative, interactive format.
              </li>
            </ul>
            <h3>2. Early Design Concepts and Prototyping</h3>
            <p>
              During the design phase of the game, I explored two main ideas to
              structure the gameplay experience:
            </p>
            <ul>
              <li>
                <strong>Dynamic Node Graph Display</strong>: This approach would
                visually display the actor connection nodes in real-time during
                gameplay, allowing players to see the web of connections as they
                navigated through the game.
              </li>
              {/* Embed Image */}
              <figure className="graph-container">
                <img
                  src="/Network_version.png"
                  alt="Dynamic Node Graph Display"
                  className="graph-image"
                />
                <figcaption className="image-caption">
                  Prototype of Dynamic Node Graph Display
                </figcaption>
              </figure>
              <li>
                <strong>Simplified Gameplay Screen</strong>: A minimalist
                approach, focusing solely on user input and immediate feedback,
                without the added complexity of showing the underlying graph
                during gameplay.
              </li>
              {/* Embed Image */}
              <figure className="graph-container">
                <img
                  src="/Simple_version.png"
                  alt="Simplified Gameplay Screen"
                  className="graph-image"
                />
                <figcaption className="image-caption">
                  Prototype of Simplified Gameplay Screen
                </figcaption>
              </figure>
            </ul>

            <h3>3. User Research Findings </h3>
            <p>
              To determine the most user-friendly approach, I conducted
              interviews with potential players. These interviews provided
              valuable insights:
            </p>
            <ul>
              <li>
                <strong>User Preference for Simplicity</strong>: Players
                expressed a preference for a cleaner interface that minimized
                distractions, emphasizing that simplicity allowed them to focus
                on solving the actor connection puzzle without feeling
                overwhelmed.
              </li>
              <li>
                <strong>Cognitive Load Consideration</strong>: Observations
                during testing revealed that displaying the dynamic graph added
                unnecessary complexity, especially for users unfamiliar with
                graph-based systems. This detracted from the intended fun and
                challenge of the game.
              </li>
            </ul>
            <h3>4. Technical Considerations</h3>
            <p>
              Apart from user feedback, I also recognized a critical technical
              challenge of dynamically rendering and navigating a node graph in
              real-time. It would significantly increase computational overhead
              and could lead to performance issues, especially for users on
              devices with limited processing power.
            </p>
            <h3>5. Final Design Decision</h3>
            <p>
              Based on these findings, I chose to prioritize simplicity for the
              main gameplay screen. The final implementation featured:
            </p>
            <ul>
              <li>
                <strong>Minimalist Gameplay Interface</strong>: A clean layout
                where users could input guesses and receive immediate feedback
                without being distracted by the graph visualization.
              </li>
              {/* Embed Image */}
              <figure className="graph-container">
                <img
                  src="/simple.png"
                  alt="Simplified Gameplay Screen"
                  className="graph-image"
                />
                <figcaption className="image-caption">
                  Final Simplified Gameplay Screen
                </figcaption>
              </figure>
              <li>
                <strong>Dedicated Graph Visualization Page</strong>: To cater to
                users interested in exploring actor connections visually, I
                created a separate page featuring an interactive, explorable
                node graph. This allowed players to dive deeper into the
                relationships at their own pace, without impacting the core
                gameplay experience.
              </li>
              {/* Embed Image */}
              <figure className="graph-container">
                <img
                  src="/graph_page.png"
                  alt="Graph Visualization Page"
                  className="graph-image"
                />
                <figcaption className="image-caption">
                  Screen 1: Final Graph Visualization Page
                </figcaption>
              </figure>
              {/* Embed Image */}
              <figure className="graph-container">
                <img
                  src="/graph_page2.png"
                  alt="Graph Visualization Page 2"
                  className="graph-image"
                />
                <figcaption className="image-caption">
                  Screen 2: Final Graph Visualization Page
                </figcaption>
              </figure>
            </ul>
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.03 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>UI/UX Design Process</h2>
            <h3>User-Centric Design Philosophy</h3>
            <p>
              The UI/UX design for the game was aimed to deliver an intuitive
              and engaging experience, especially given its novel and unfamiliar
              gameplay mechanics.
            </p>
            <h3>1. Problem Definition</h3>
            <ul>
              <li>
                <strong>Goal</strong>: Simplify and clarify the gameplay
                mechanics so that users could easily understand and engage
                with the task of connecting actors.
              </li>
              <li>
                <strong>Challenge</strong>: Design and develop an intuitive interface that conveys a graph-based network of actors and movies without overwhelming users, while leveraging the potential of network analysis in an innovative, interactive format.
              </li>
            </ul>
            <h3>2. Early Design Concepts and Prototyping</h3>
            <p>
              During the design phase of the game, I explored two main ideas to
              structure the gameplay experience:
            </p>
            <ul>
              <li>
                <strong>Dynamic Node Graph Display</strong>: This approach would
                visually display the actor connection nodes in real-time during
                gameplay, allowing players to see the web of connections as they
                navigated through the game.
              </li>
              {/* Embed Image */}
              <figure className="graph-container">
                <img
                  src="/Network_version.png"
                  alt="Dynamic Node Graph Display"
                  className="graph-image"
                />
                <figcaption className="image-caption">
                  Prototype of Dynamic Node Graph Display
                </figcaption>
              </figure>
              <li>
                <strong>Simplified Gameplay Screen</strong>: A minimalist
                approach, focusing solely on user input and immediate feedback,
                without the added complexity of showing the underlying graph
                during gameplay.
              </li>
              {/* Embed Image */}
              <figure className="graph-container">
                <img
                  src="/Simple_version.png"
                  alt="Simplified Gameplay Screen"
                  className="graph-image"
                />
                <figcaption className="image-caption">
                  Prototype of Simplified Gameplay Screen
                </figcaption>
              </figure>
            </ul>

            <h3>3. User Research Findings </h3>
            <p>
              To determine the most user-friendly approach, I conducted
              interviews with potential players. These interviews provided
              valuable insights:
            </p>
            <ul>
              <li>
                <strong>User Preference for Simplicity</strong>: Players
                expressed a preference for a cleaner interface that minimized
                distractions, emphasizing that simplicity allowed them to focus
                on solving the actor connection puzzle without feeling
                overwhelmed.
              </li>
              <li>
                <strong>Cognitive Load Consideration</strong>: Observations
                during testing revealed that displaying the dynamic graph added
                unnecessary complexity, especially for users unfamiliar with
                graph-based systems. This detracted from the intended fun and
                challenge of the game.
              </li>
            </ul>
            <h3>4. Technical Considerations</h3>
            <p>
              Apart from user feedback, I also recognized a critical technical
              challenge of dynamically rendering and navigating a node graph in
              real-time. It would significantly increase computational overhead
              and could lead to performance issues, especially for users on
              devices with limited processing power.
            </p>
            <h3>5. Final Design Decision</h3>
            <p>
              Based on these findings, I chose to prioritize simplicity for the
              main gameplay screen. The final implementation featured:
            </p>
            <ul>
              <li>
                <strong>Minimalist Gameplay Interface</strong>: A clean layout
                where users could input guesses and receive immediate feedback
                without being distracted by the graph visualization.
              </li>
              {/* Embed Image */}
              <figure className="graph-container">
                <img
                  src="/simple.png"
                  alt="Simplified Gameplay Screen"
                  className="graph-image"
                />
                <figcaption className="image-caption">
                  Final Simplified Gameplay Screen
                </figcaption>
              </figure>
              <li>
                <strong>Dedicated Graph Visualization Page</strong>: To cater to
                users interested in exploring actor connections visually, I
                created a separate page featuring an interactive, explorable
                node graph. This allowed players to dive deeper into the
                relationships at their own pace, without impacting the core
                gameplay experience.
              </li>
              {/* Embed Image */}
              <figure className="graph-container">
                <img
                  src="/graph_page.png"
                  alt="Graph Visualization Page"
                  className="graph-image"
                />
                <figcaption className="image-caption">
                  Screen 1: Final Graph Visualization Page
                </figcaption>
              </figure>
              {/* Embed Image */}
              <figure className="graph-container">
                <img
                  src="/graph_page2.png"
                  alt="Graph Visualization Page 2"
                  className="graph-image"
                />
                <figcaption className="image-caption">
                  Screen 2: Final Graph Visualization Page
                </figcaption>
              </figure>
            </ul>
          </motion.div>
        )}

        {isMobile ? (
          <div className="movie-game-description">
            <h2>Development and Coding</h2>

            <h3>Backend Engineering</h3>
            <ul>
              <li>
                Built a Python and Flask backend to handle complex game logic
                and state management.
              </li>
              <li>
                Preprocessed actor-movie data using Python's NetworkX library,
                utilizing Dijkstra's algorithm for dynamic pathfinding.
              </li>
            </ul>
            <h3>Frontend Implementation</h3>
            <ul>
              <li>
                Developed the React-based frontend to ensure a seamless user
                experience with an emphasis on interaction design.
              </li>
              <li>
                Integrated responsive design principles to ensure cross-device
                compatibility.
              </li>
            </ul>
            <h3>Key Functionalities</h3>
            <ul>
              <li>
                <strong>Dynamic Path Recalculation</strong>: Incorrect guesses
                trigger a recalibration of the shortest path using Dijkstra's
                algorithm.
              </li>
              <li>
                <strong>API Integrations</strong>: Used TMDb API for fetching
                actor images and OpenAI GPT for contextually relevant hints.
              </li>
            </ul>
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.15 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>Development and Coding</h2>

            <h3>Backend Engineering</h3>
            <ul>
              <li>
                Built a Python and Flask backend to handle complex game logic
                and state management.
              </li>
              <li>
                Preprocessed actor-movie data using Python's NetworkX library,
                utilizing Dijkstra's algorithm for dynamic pathfinding.
              </li>
            </ul>
            <h3>Frontend Implementation</h3>
            <ul>
              <li>
                Developed the React-based frontend to ensure a seamless user
                experience with an emphasis on interaction design.
              </li>
              <li>
                Integrated responsive design principles to ensure cross-device
                compatibility.
              </li>
            </ul>
            <h3>Key Functionalities</h3>
            <ul>
              <li>
                <strong>Dynamic Path Recalculation</strong>: Incorrect guesses
                trigger a recalibration of the shortest path using Dijkstra's
                algorithm.
              </li>
              <li>
                <strong>API Integrations</strong>: Used TMDb API for fetching
                actor images and OpenAI GPT for contextually relevant hints.
              </li>
            </ul>
          </motion.div>
        )}

        {isMobile ? (
          <div className="movie-game-description">
            <h2>Outcomes & Learnings</h2>
            <ul>
              <li>
                <strong>User Engagement</strong>: Successfully balanced game
                difficulty through dynamic path recalculations and a hint
                system, enhancing retention and user engagement.
              </li>
              <li>
                <strong>Enhanced Skills</strong>: Deepened expertise in
                full-stack development, API integration, and algorithm design,
                while honing my UI/UX capabilities for creating intuitive and
                visually appealing interfaces.
              </li>
              <li>
                <strong>Design Takeaway</strong>: The iterative feedback-driven
                process improved both usability and visual appeal, which are
                critical for user-centered design.
              </li>
            </ul>
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>Outcomes & Learnings</h2>
            <ul>
              <li>
                <strong>User Engagement</strong>: Successfully balanced game
                difficulty through dynamic path recalculations and a hint
                system, enhancing retention and user engagement.
              </li>
              <li>
                <strong>Enhanced Skills</strong>: Deepened expertise in
                full-stack development, API integration, and algorithm design,
                while honing my UI/UX capabilities for creating intuitive and
                visually appealing interfaces.
              </li>
              <li>
                <strong>Design Takeaway</strong>: The iterative feedback-driven
                process improved both usability and visual appeal, which are
                critical for user-centered design.
              </li>
            </ul>
            <a
              href="https://actortoactor.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="play-button2">Play the Game</button>
            </a>
          </motion.div>
        )}
      </div>
      <ProjectNavigator currentProject="Actor to Actor Game" />
      <Footer />
    </div>
  );
}

export default MovieGamePage;
