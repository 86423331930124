import React from 'react';
import './Footer.css'; // We'll create this CSS file next

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-content">
      <div className="footer-section">
          <h3>CONTACT</h3>
          <a href="mailto:shunueno@stanford.edu" className="email-link">shunueno@stanford.edu</a>
        </div>
        <div className="footer-section">
          <h3>CONNECT</h3>
          <ul className="social-links">
            <li>
              <a href="https://linkedin.com/in/shunueno" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
            </li>
            <li>
              <a href="https://drive.google.com/file/d/1sm7A46rJKgjh2Y-o_LLrJRdlu5-0rWHb/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                Resume
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-name">Shun Ueno</p>
        
        <p className="footer-copyright">&copy; {new Date().getFullYear()}</p>
        
      </div>
    </footer>
  );
}

export default Footer;